<template>
  <v-dialog
    v-model="emailForm"
    :close-on-content-click="false"
    max-width="600px"
    offset-x
  >
    <v-card class="white">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        v-on:submit.prevent="emailMe"
      >
        <v-text-field
          v-model="email"
          :rules="emailRules"
          name="email"
          label="E-mail"
          required
        ></v-text-field>

        <v-textarea
          v-model="message"
          :rules="messageRules"
          name="message"
          label="Message"
          required
        ></v-textarea>

        <v-btn :disabled="!valid" color="success" class="mr-4" @click="emailMe">
          Email me
        </v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      valid: true,
      email: "",
      emailForm: true,
      message: "",
      messageRules: [(v) => !!v || "Message is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    emailMe: function () {
      this.$refs.form.validate();

      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          message: this.message,
        }),
      };

      fetch("https://formspree.io/f/xoqpbqed", options).then((response) => {
        if (!response.ok) {
          throw new Error("Email was not sent successfully");
        }
      });
    },
  },
};
</script>
