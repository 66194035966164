<template>
  <v-app>
    <v-app-bar app dark>
      <v-container fluid>
        <v-row>
          <v-col align="center">
            <v-btn icon @click="emailForm = !emailForm" dark>
              <v-icon>email</v-icon>
            </v-btn>
          </v-col>
          <v-col align="center">
            <v-btn icon href="./CV.pdf" target="_blank">
              <v-avatar>CV</v-avatar></v-btn
            >
          </v-col>
          <v-col align="center" class="hidden-md-and-down">
            <v-btn height="48" text to="/year_review">Year Reviews</v-btn>
          </v-col>
          <v-col align="center">
            <v-btn icon v-bind:href="gitlab.href" target="_blank">
              <v-avatar>
                <img
                  v-bind:src="gitlab.image"
                  alt="Andrew Perry's personal Gitlab page"
                />
              </v-avatar>
            </v-btn>
          </v-col>
          <v-col align="center" class="hidden-md-and-down">
            <v-btn icon v-bind:href="github.href" target="_blank">
              <v-avatar>
                <img
                  v-bind:src="github.image"
                  alt="Andrew Perry's personal Github
          page"
                />
              </v-avatar>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-row no-gutters>
        <email-form v-if="emailForm"></email-form>
        <router-view></router-view>
      </v-row>
    </v-main>
    <!-- <v-footer app>
    </v-footer> -->
  </v-app>
</template>
<style>
html {
  overflow-y: auto;
}
</style>
<script>
import github from "../public/img/PNG/GitHub-Mark-Light-64px.png";
import gitlab from "../public/img/gitlab-icon-rgb.svg";
import EmailForm from "./EmailForm.vue";

export default {
  components: { EmailForm },
  name: "App",
  data() {
    return {
      valid: true,
      emailForm: false,
      email: "",
      message: "",
      messageRules: [(v) => !!v || "Message is required"],
      github: {
        image: github,
        href: "https://github.com/andyjp94",
      },
      gitlab: {
        image: gitlab,
        href: "https://gitlab.com/andyjp94",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
};
</script>
