import Vue from "vue";
import Router from "vue-router";

const Home = () => import("./Home.vue");
const YearReview = () => import("./YearReview.vue");
const Sudoku = () => import("./Sudoku.vue");

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/year_review",
      name: "year_review",
      component: YearReview,
    },
    {
      path: "/sudoku",
      name: "sudoku",
      component: Sudoku,
    },
  ],
});
